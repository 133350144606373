import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useForm, Controller } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { Text, InputText, Button } from '@dls/web';
import {
  BodyText,
  ButtonWrapper,
  FieldWrapper,
  Heading
} from '../../components/styles';
import { flowConfigState } from '../..';
import { formDataState} from './utils';
import { generateJWE } from '../../helpers/encryption';
import Api from '../../helpers/Api';
import SETTINGS from '../../constants/appSettings'
import { COPY, CODE, ERROR } from '../../constants/appCopy.json';

export default function verifyAccount({setRoute, onCancel}) {
  const captchaRef = React.useRef();
  const [formData, setFormData] = useRecoilState(formDataState);
  const flowConfig = useRecoilValue(flowConfigState);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, errors, setError } = useForm({
    mode: 'onTouched'
  })

  const handleAccountValidation = (value) => {
    if (!value) {
      return 'Please provide your mobile or billing account number';
    }
    return true;
  };

  const handleAccount = async (data) => {
    setLoading('inProgress');
    try {
      let accountNo = data.account.trim();
      if (accountNo.startsWith('+65')) {
        accountNo = accountNo.slice(3);
      } else if (accountNo.startsWith('65')) {
        accountNo = accountNo.slice(2);
      }
      const token = await captchaRef.current.executeAsync();
      const keyResponse = await Api.getKey();
      const encryptPayload = JSON.stringify({
        nric: formData?.nric,
        nricType: formData?.nricType,
        serviceNumber: accountNo,
      })

      // encrypt data
      const header = { enc: 'A128GCM', alg: 'RSA-OAEP-256' };
      const jwe = await generateJWE(encryptPayload, keyResponse?.body?.keys[0], header);
    
      const payload = {
        referenceId: keyResponse?.body?.keys[0].kid,
        flowId: flowConfig?.flowId,
        data: jwe,
        reCaptchaToken: token
      };
      
      const response = await Api.verifyAccount(payload);
      if (response?.body) {
        const { serviceNumberType, sendOtpResult } = response.body;
        const updatedFormData = { 
          ...formData, 
          serviceType: serviceNumberType 
        };
      
        if (serviceNumberType === 'BILLING') {
          setFormData({
            ...updatedFormData,
            serviceNo: accountNo
          });
          setRoute({step:3, view:'createAccount', showStepper: true});
        } else {
          setFormData({
            ...updatedFormData,
            serviceNo: accountNo,
            mode: 'SMS',
            contact: accountNo,
            contactForDisplay: `+65 **** ${accountNo.slice(4)}`,
            token: sendOtpResult?.token,
          });
          setRoute({step:0, view:'otp', showStepper: false});
        }
      } else {
        const userAlreadyExistsMsg = (
          <>
            Make sure your details are correct or <Text type="link" inline onMouseDown={() => navigate(`/login${window.location.search}`)}>log in </Text> if you already have an account
          </>
        );

        const errorCode = response?.error?.data?.code;
        const errorMessages = {
          'INVALID_RECAPTCHA': 'Invalid reCAPTCHA',
          'INVALID_REQUEST':  ERROR.SOMETHING_WRONG,
          default: ERROR.SOMETHING_WRONG
        };
        if(errorCode === 'INVALID_POSTPAID_NUMBER' || errorCode === 'INVALID_BILLING_ACCOUNT_NUMBER'){
          setError('account', { message: 'Make sure your details are correct' });
          return;
        }
        if(errorCode === 'USERNAME_ALREADY_EXISTS'){
          setError('account', { message: userAlreadyExistsMsg });
          return;
        }
        if(errorCode === 'OTP_BLOCKED_TOO_MANY' || errorCode === 'OTP_BLOCKED_TOO_EARLY'){
          setRoute({step:0, view:'mutipleAttempts', showStepper: false});
          return;
        }
        setError('account', { message: errorMessages[errorCode] || errorMessages.default });
      }
    } catch (e) {
      setError('account', { message: ERROR.SOMETHING_WRONG });
    } finally {
      setLoading(false);
    }
  }
  
  return (
    <>
      <ReCAPTCHA
        ref={captchaRef}
        size="invisible"
        sitekey={SETTINGS.CAPTCHA_SITE_KEY}
      />
    <Heading>Help us confirm it's you</Heading>
    <BodyText type="body">
      Enter your Singtel mobile or billing account number.
    </BodyText>
    <form onSubmit={handleSubmit(handleAccount)}>
      <FieldWrapper>
      <Controller
        name="account"
        rules={{ validate: handleAccountValidation }}
        defaultValue=""
        control={control}
        render={({ value, onChange, onBlur, ref }) => (
          <InputText
            bgColor="haze"
            label="Mobile/Billing account number"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!errors.account}
            hintMessage={errors.account?.message  || 'Billing account number can be found in your monthly bill'}
            />
          )}
        />
      </FieldWrapper>
      <div>
        <ButtonWrapper>
          <Button fullWidth type="submit" loading={loading}>
            Continue
          </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button fullWidth secondary onClick={() => onCancel()}>
              Cancel
            </Button>
          </ButtonWrapper>
      </div>
    </form>
  </>  
  )
}