import React from 'react';
import { useSetRecoilState } from 'recoil';
import { navigate } from 'gatsby';
import { Button, Text } from '@dls/web';
import {
  Heading,
  BodyText,
  ButtonWrapper,
} from '../../components/styles';
import { SuccessStatusIcon } from "../../icons/SuccessStatusIcon";
import { loginBannerErrorState } from '../LoginView/utils';

const RegistrationSuccess = () => {
  const setLoginBannerError = useSetRecoilState(loginBannerErrorState);
  setLoginBannerError('');
  return (
    <>
      <div style={{padding: '24px 0', textAlign: 'center'}}>
        <SuccessStatusIcon />
      </div>
    <Heading> Account created </Heading>
    <BodyText as="div">
      <Text type="body">You can now log in to access your Singtel services. </Text>
    </BodyText>
    <div>
    <ButtonWrapper>
        <Button
          fullWidth
          secondary
          onClick={() => navigate(`/login${window.location.search}`)}
        >
          Back to login
        </Button>
      </ButtonWrapper>
    </div>
    </>
  );
};

export default RegistrationSuccess;
