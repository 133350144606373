import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Grid, Row, Column, Spacing } from '@dls/web';
import RegistrationView from '../views/RegistrationView';
import Footer from '../components/Footer';
import ColorLogoMobile from '@dls/assets/images/logo_singtel@2x.png';
import ColorLogoDesktop from '@dls/assets/images/logo_singtel@3x.png';
import media from '../helpers/media';
import { from } from 'core-js/core/array';

const OverrideGlobalStyle = createGlobalStyle`
  html {
    background-color: white;
    background-image: none;
  }
  ${media.sm`
    div[class*='ModalContainer'] > div {
      width: 450px;
      > div > div {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  `}
  ${media.md`    
    div[class*='ModalContainer'] > div {
      width: 460px;
      > div > div {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  `}
`;

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: #ffffff;
`;

const Header  = () => {
  const HeaderWrapper = styled(Grid)`
    padding: 0px;
    border-top: #E71336 solid 4px;
    div[class*='Grid'], div[class*='Row'], div[class*='Column']{
      padding: 0px;
    }
  `;

  const LogoWrapper = styled(Column)`
    &&& {
      padding: 0px 16px;
    }
  `;

  const LogoImage = styled.img`
    max-width: 86px;
  `;

  return (
      <HeaderWrapper fluid>
        <Row>
          <Column>
              <Grid>
                <Row>
                  <LogoWrapper>
                    <LogoImage src={ColorLogoMobile} alt="Logo" />
                  </LogoWrapper>
                </Row>
              </Grid>
          </Column>
        </Row>
      </HeaderWrapper>
  );
}

export default function Login() {
  return (
    <>
      <OverrideGlobalStyle />
      <Header />
      <RegistrationView />
      <Footer />
    </>
  );
}

