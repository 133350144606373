import { atom } from 'recoil';

export const formDataState = atom({
  key: 'formDataState',
  default: {
    nric: '',
    nricType: '',
    serviceNo: '',
    serviceType: '',
    mode: '',
    contact: '',
    contactForDisplay: '',
    token: '',
    emailToken: '',
  },
});