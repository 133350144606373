import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { navigate } from 'gatsby-link';
import { InputPassword, Button } from '@dls/web';
import { useForm, Controller } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import BoxWrapper from '../../components/Layout/BoxWrapper';
import PasswordCheckList from '../ResetPasswordView/checklist';
import {
  BodyText,
  ButtonWrapper,
  FieldWrapper,
  Heading
} from '../../components/styles';
import { flowConfigState } from '../..';
import { formDataState} from './utils';
import Api from '../../helpers/Api';
import { generateJWE } from '../../helpers/encryption';
import SETTINGS from '../../constants/appSettings'
import { COPY, CODE, ERROR } from '../../constants/appCopy.json';

export default function ResetPasswordView({setRoute, onCancel}) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const flowConfig = useRecoilValue(flowConfigState);
  const [formData, setFormData] = useRecoilState(formDataState);
  const captchaRef = React.useRef();

  const { watch, control, handleSubmit, errors, setError, getValues } = useForm({
    mode: 'onTouched'
  });

  const checkPassword = watch("password");

  const updateStatus = (data) => {
    setStatus(data);
  }

  const handlePasswordValidation = (data) => {
    if (!data){
      return "Enter a new password first";
    }
    return null;
  }

  const handleConfirmPasswordValidation = (data) => {
    const values = getValues();
    if (values.password !== values.confirmpassword) {
      return "check that your passwords match";
    }
    return null;
  }

  const handleResetPassword = async (data) => {
    if(!status) return;
    setLoading('inProgress');
    try {
      const token = await captchaRef.current.executeAsync();
      const keyResponse = await Api.getKey();
      
      const commonPayload = {
        nric: formData?.nric,
        nricType: formData?.nricType,
        email: formData.contact,
        emailOtpToken: formData.emailToken,
        password: data.password
      };
      
      const specificPayload = formData?.serviceType === 'BILLING'
        ? { billingAccountNumber: formData?.serviceNo }
        : { postpaidNumber: formData?.serviceNo, postpaidNumberOtpToken: formData.token };
      
      const encryptPayload = JSON.stringify({
        ...commonPayload,
        ...specificPayload
      })

      console.log(encryptPayload);
      const header = { enc: 'A128GCM', alg: 'RSA-OAEP-256' };
      const jwe = await generateJWE(encryptPayload, keyResponse?.body?.keys[0], header);
      
      const payload = {
        referenceId: keyResponse?.body?.keys[0].kid,
        flowId: flowConfig?.flowId,
        data: jwe,
        reCaptchaToken: token
      };
      
      const resetResponse = await Api.registerAccount(payload);
      if(resetResponse?.body) {
        setRoute({step:0, view:'success', showStepper: false});
      } else {
        const errorCode = resetResponse?.error?.data?.code;
        const errorMessages = {
          'INVALID_RECAPTCHA': 'Invalid reCAPTCHA',
          'INVALID_REQUEST': ERROR.SOMETHING_WRONG,
          default: ERROR.SOMETHING_WRONG
        };
        setError('password', { message: errorMessages[errorCode] || errorMessages.default });
      }
    } catch (e) {
      setError('password', { message: ERROR.SOMETHING_WRONG });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <ReCAPTCHA
        ref={captchaRef}
        size="invisible"
        sitekey={SETTINGS.CAPTCHA_SITE_KEY}
      />
      <Heading>Create password</Heading>
      <BodyText type="body">
        Keep your account safe with a strong password.
      </BodyText>
      <form onSubmit={handleSubmit(handleResetPassword)}>
        <FieldWrapper>
        <Controller
          name="password"
          rules={{ validate: handlePasswordValidation }}
          defaultValue=""
          control={control}
          render={({ value, onChange, onBlur }) => (
            <InputPassword
              bgColor="haze"
              label="New password"
              value={value}
              onChange={onChange}
              onBlur={(e) => {}}
              error={!!(errors.password && errors.password.message)}
              hintMessage={errors.password && errors.password.message}
              />
            )}
          />
        </FieldWrapper>
        <PasswordCheckList value={checkPassword} updateStatus={updateStatus}/>
        <FieldWrapper>
          <Controller
          name="confirmpassword"
          rules={{ validate: handleConfirmPasswordValidation }}
          defaultValue=""
          control={control}
          render={({ value, onChange, onBlur }) => (
          <InputPassword
            bgColor="haze"
            label="Confirm password"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onPaste={(e) => {
              e.preventDefault();
              setError('confirmpassword', { message: 'Type in your password instead' });
            }}
            error={!!(errors.confirmpassword && errors.confirmpassword.message)}
            hintMessage={errors.confirmpassword && errors.confirmpassword.message}
          />
          )}
          />
        </FieldWrapper>
        <div>
          <ButtonWrapper>
            <Button fullWidth type="submit" loading={loading}>
              Confirm
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
          <Button fullWidth secondary onClick={() => onCancel()}>
            Cancel
          </Button>
        </ButtonWrapper>
        </div>
      </form>
    </>
  );
}
