import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Stepper, Modal, Button } from  '@dls/web';
import styled from 'styled-components';
import Step1 from './verifyNric';
import Step2 from './verifyAccount';
import Step3 from './otp';
import Step4 from './onepassId';
import Step5 from './password';
import Step6 from './success';
import Step7 from './mutipleAttempts';
import {
  ModalText,
  ButtonWrapper,
} from '../../components/styles';
import media from '../../helpers/media';

const StepperWrapper = styled.div`
  width: 100%;
  padding: 24px;
  margin: 0 auto;
  box-sizing: border-box;
  ${media.sm`
    width: 450px;
    padding: 40px 40px 0;
  `}
  ${media.md`
    width: 460px;
    padding: 40px 40px 0;
  `}
  div[class*='StepContainer']:last-child {
    display: none;
  }
  div[class*='StepContainer']:nth-last-child(2) {
    flex: 0;
  }
`;

const ContentWrapper = styled.div`
  background: white;
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;
  box-sizing: border-box;
  ${media.sm`
    width: 450px;
    padding: 40px;
  `}
  ${media.md`
    width: 460px;
    padding: 40px;
  `}

  .dls-otp-input--containerStyle {
    display: flex;
  }
  .dls-otp-input--inputStyle:last-child {
    margin-right: 0px;
  }

`;

const RegistrationView = () => {
  const [step, setStep] = useState(1);
  const [view, setView] = useState('verifyNric');
  const [showStepper,setShowStepper] = useState(true);
  const [modal, setModal] = useState(false);

  const onCancel = () => {
    setModal(true);
  }
  const setRoute = (data) => {
    setStep(data.step);
    setView(data.view);
    setShowStepper(data.showStepper);
  }

  let currentView;
  switch (view) {
    case 'verifyNric':
      currentView = <Step1 setRoute={setRoute} onCancel={onCancel} />;
      break;
    case 'verifyAccount':
      currentView = <Step2 setRoute={setRoute} onCancel={onCancel} />;
      break;
    case 'otp':
      currentView = <Step3 setRoute={setRoute} />;
      break;
    case 'createAccount':
      currentView = <Step4 setRoute={setRoute} onCancel={onCancel} />;
      break;
    case 'createPassword':
      currentView = <Step5 setRoute={setRoute} onCancel={onCancel} />;
      break;
    case 'success':
      currentView = <Step6 />;
      break;
    case 'mutipleAttempts':
      currentView = <Step7 />;
      break;
    default:
      currentView = <Step1 setRoute={setRoute} />;
      break;
  }

  return (
    <>
      {showStepper && (
        <StepperWrapper>
          <Stepper activeStep={step} totalSteps={4}/>
        </StepperWrapper>
      )}
      <ContentWrapper>
        {currentView}
      </ContentWrapper>
      <Modal
        visible={modal}
        onClose={() => { setModal(false);}}
        title={'Cancel account creation?'}
      >
        <Modal.Content style={{width: '360px'}}>
          <ModalText type="body">
            All your information will not be saved. 
          </ModalText>
          <div>
            <ButtonWrapper>
              <Button fullWidth onClick={() => navigate(`/login${window.location.search}`)}>
                Confirm
              </Button>
          </ButtonWrapper>
        </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default RegistrationView;