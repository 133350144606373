import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useForm, Controller } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { Text, InputText, Button } from '@dls/web';
import {
  BodyText,
  ButtonWrapper,
  FieldWrapper,
  Heading
} from '../../components/styles';
import { flowConfigState } from '../..';
import { formDataState } from './utils';
import { isValidEmail } from '../../helpers/utils';
import { generateJWE } from '../../helpers/encryption';
import Api from '../../helpers/Api';
import SETTINGS from '../../constants/appSettings'
import { COPY, CODE, ERROR } from '../../constants/appCopy.json';

export default function verifyAccount({setRoute, onCancel}) {
  const captchaRef = React.useRef();
  const [formData, setFormData] = useRecoilState(formDataState);
  const flowConfig = useRecoilValue(flowConfigState);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, errors, setError, reset, getValues } = useForm({
    mode: 'onTouched'
  })

  const handleEmailValidation = (email) => {
    const values = getValues()
    if (!isValidEmail(email)){
      return "Enter a valid email";
    }
    return null;
   }

  const maskEmail = (email) => {
    const [localPart, domainPart] = email.split('@');
    const maskedLocalPart = localPart.slice(0, 2) + '*'.repeat(localPart.length - 2);
    return `${maskedLocalPart}@${domainPart}`;
  }

  const handleAccount = async (data) => {
    setLoading('inProgress');
    const accountEmail = data.email.toLowerCase();
    try {
      const token = await captchaRef.current.executeAsync();
      const keyResponse = await Api.getKey();
      const encryptPayload = JSON.stringify({
        email: accountEmail
      });

      // encrypt data
      const header = { enc: 'A128GCM', alg: 'RSA-OAEP-256' };
      const jwe = await generateJWE(encryptPayload, keyResponse?.body?.keys[0], header);

      const payload = {
        referenceId: keyResponse?.body?.keys[0].kid,
        flowId: flowConfig?.flowId,
        data: jwe,
        reCaptchaToken: token
      };
      const response = await Api.verifyEmailForAccount(payload);
      if (response?.body) {
        const { sendOtpResult } = response.body;
        setFormData({
          ...formData,
            mode: 'EMAIL',
            contact: accountEmail,
            contactForDisplay: maskEmail(accountEmail),
            emailToken: sendOtpResult?.token,
        });
        setRoute({step:0, view:'otp', showStepper: false});
      } else {
        const userAlreadyExistsMsg = (
          <>
            Make sure your details are correct or <Text type="link" inline onMouseDown={() => navigate(`/login${window.location.search}`)}>log in </Text> if you already have an account
          </>
        );
        const blockSingnetIDs = (
          <>
            SingNet email accounts are temporarily not supported. Use an alternative email or mobile instead. <Text type="link" inline onMouseDown={() => window.open('https://www.singtel.com/personal/support/singnet#accountupdate', '_blank').focus()}> Learn more</Text>
          </>
        )
        const errorCode = response?.error?.data?.code;
        const errorMessages = {
          'INVALID_RECAPTCHA': 'Invalid reCAPTCHA',
          'INVALID_REQUEST': ERROR.SOMETHING_WRONG,
          default: ERROR.SOMETHING_WRONG
        };
        if(errorCode === 'USERNAME_ALREADY_EXISTS'){
          setError('email', { message: userAlreadyExistsMsg });
          return;
        }
        if(errorCode === 'SINGNET_EMAIL_CAN_NOT_BE_USED'){
          setError('email', { message: blockSingnetIDs });
          return;
        }
        if(errorCode === 'OTP_BLOCKED_TOO_MANY' || errorCode === 'OTP_BLOCKED_TOO_EARLY'){
          setRoute({step:0, view:'mutipleAttempts', showStepper: false});
          return;
        }
        setError('email', { message: errorMessages[errorCode] || errorMessages.default });
      }
    } catch (e) {
      console.log(e);
      setError('email', { message: ERROR.SOMETHING_WRONG });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <ReCAPTCHA
        ref={captchaRef}
        size="invisible"
        sitekey={SETTINGS.CAPTCHA_SITE_KEY}
      />
    <Heading>Create OnePass ID</Heading>
    <BodyText type="body">
      This will be used for login and verification.
    </BodyText>
    <form onSubmit={handleSubmit(handleAccount)}>
      <FieldWrapper>
      <Controller
        name="email"
        rules={{ validate: handleEmailValidation }}
        defaultValue=""
        control={control}
        render={({ value, onChange, onBlur, ref }) => (
          <InputText
            bgColor="haze"
            label="Email"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!(errors.email && errors.email.message)}
            hintMessage={errors.email && errors.email.message}
            />
          )}
        />
      </FieldWrapper>
      <div>
        <ButtonWrapper>
          <Button fullWidth type="submit" loading={loading}>
            Get OTP
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button fullWidth secondary onClick={() => onCancel()}>
            Cancel
          </Button>
        </ButtonWrapper>
      </div>
    </form>
  </>
  )
}
