import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useForm, Controller } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { InputText, Button, DropdownInput } from '@dls/web';
import {
  BodyText,
  ButtonWrapper,
  FieldWrapper,
  Heading
} from '../../components/styles';
import { flowConfigState } from '../..';
import { formDataState} from './utils';
import { generateJWE } from '../../helpers/encryption';
import Api from '../../helpers/Api';
import SETTINGS from '../../constants/appSettings';
import { loginBannerErrorState } from '../LoginView/utils';
import { COPY, CODE, ERROR } from '../../constants/appCopy.json';

export default function VerifyNric({ setRoute, onCancel }) {
  const captchaRef = useRef();
  const flowConfig = useRecoilValue(flowConfigState);
  const [formData, setFormData] = useRecoilState(formDataState);
  const setBannerError = useSetRecoilState(loginBannerErrorState);
  const [loading, setLoading] = useState(false);
  const [showNricType, setShowNricType] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    setError,
    errors
  } = useForm({
    mode: 'onTouched'
  });

  const nricValue = watch("nric");

  const handleNRICValidation = (nric) => {
    const regex = /^[STFGM]\d{7}[A-Z]$/;
    if (!nric) return "Please provide your NRIC/FIN";
    if (!regex.test(nric)) return "Invalid NRIC/FIN";
    return true;
  };

  const handleNRICTypeValidation = (nricType) => {
    if ((nricValue?.startsWith('F') || nricValue?.startsWith('G') || nricValue?.startsWith('M')) && !nricType) {
      return "Please select your document type";
    }
    return true;
  };

  const handleNRIC = async (data) => {
    setLoading('inProgress');
    try {
      const token = await captchaRef.current.executeAsync();
      const keyResponse = await Api.getKey();
      const encryptPayload = JSON.stringify({
        nric: data.nric,
        nricType: data.nricType || "CPR"
      });

      const header = { enc: 'A128GCM', alg: 'RSA-OAEP-256' };
      const jwe = await generateJWE(encryptPayload, keyResponse.body.keys[0], header);

      const payload = {
        referenceId: keyResponse.body.keys[0].kid,
        flowId: flowConfig.flowId,
        data: jwe,
        reCaptchaToken: token
      };

      const response = await Api.verifyNRIC(payload);
      if (response?.body) {
        setFormData({
          ...formData,
          nric: data.nric,
          nricType: data.nricType || "CPR"
        });
        setRoute({step:2, view:'verifyAccount', showStepper: true});
      } else {
        const errorCode = response?.error?.data?.code;
        const errorMessages = {
          'INVALID_RECAPTCHA': 'Invalid reCAPTCHA',
          default: ERROR.SOMETHING_WRONG
        };
        if(errorCode === 'INVALID_CUST_ID'){
          setBannerError('We could not proceed with your request. If you have a OnePass account, try logging in again.');
          navigate(`/login${window.location.search}`);
          return;
        }
        if(errorCode === 'CUST_ID_ALREADY_EXISTS') {
          setBannerError('CUST_ID_ALREADY_EXISTS');
          navigate(`/login${window.location.search}`);
          return;
        }
        setError('nric', { message: errorMessages[errorCode] || errorMessages.default });
      }
    } catch (e) {
      setError('nric', { message: ERROR.SOMETHING_WRONG});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (nricValue && (nricValue.startsWith('F') || nricValue.startsWith('G') || nricValue.startsWith('M'))) {
      setShowNricType(true);
    } else {
      setShowNricType(false);
    }
  }, [nricValue, setValue]);

  return (
    <>
      <ReCAPTCHA
        ref={captchaRef}
        size="invisible"
        sitekey={SETTINGS.CAPTCHA_SITE_KEY}
      />
      <Heading>Create account</Heading>
      <BodyText type="body">
        Enter the NRIC/FIN used to register your Singtel services.
      </BodyText>
      <form onSubmit={handleSubmit(handleNRIC)}>
        <FieldWrapper>
          <Controller
            name="nric"
            control={control}
            defaultValue=""
            rules={{ validate: handleNRICValidation }}
            render={({ value, onChange, onBlur }) => (
              <InputText
                bgColor="haze"
                label="NRIC/FIN"
                maxLength="9"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setValue('nric', e.target.value.toUpperCase());
                }}
                onBlur={onBlur}
                error={!!errors.nric}
                hintMessage={errors.nric?.message}
              />
            )}
          />
        </FieldWrapper>
        {showNricType && (
          <FieldWrapper>
            <Controller
              name="nricType"
              control={control}
              defaultValue=""
              rules={{ validate: handleNRICTypeValidation }}
              render={({ onChange, onBlur }) => (
                <DropdownInput
                  bgColor="haze"
                  label="Document Type"
                  items={[
                    { text: "Employment Pass", value: "EP" },
                    { text: "Work Permit", value: "WP" },
                    { text: "Social Visit Pass", value: "SVP" },
                    { text: "Foreigner Student Pass", value: "STP" },
                    { text: "Foreigner S Pass", value: "SP" },
                    { text: "Dependent Pass", value: "DP" },
                    { text: "Diplomatic", value: "DM" },
                    { text: "Non-diplomatic", value: "NDM" }
                  ]}
                  onChange={(e) => {
                    onChange(e);
                    setValue('nricType', e.value);
                  }}
                  onBlur={onBlur}
                  error={!!errors.nricType}
                  hintMessage={errors.nricType?.message}
                />
              )}
            />
          </FieldWrapper>
        )}
        <div>
          <ButtonWrapper>
            <Button fullWidth type="submit" loading={loading}>
              Continue
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button fullWidth secondary onClick={() => onCancel()}>
              Cancel
            </Button>
          </ButtonWrapper>
        </div>
      </form>
    </>
  );
}
